
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useBanners, useDeleteBanner } from '@/composables/api';
import { BannerIdOptions } from '@/services/api';

import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { BannerType, BannerPosition } from '@/interfaces/Banner';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const POSITION_OPTIONS = Object
  .entries(BannerPosition)
  .map(([label, value]) => ({ label, value }));

const TYPE_OPTIONS = Object
  .entries(BannerType)
  .map(([label, value]) => ({ label, value }));

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Position',
    placeholder: 'Please select an option',
    options: POSITION_OPTIONS
  },
  {
    type: FilterType.SELECTOR,
    label: 'Type',
    placeholder: 'Please select an option',
    options: TYPE_OPTIONS
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const route = useRoute();

    const page = ref(1);
    const keyword = ref();
    const type = ref();
    const position = ref();

    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();

    const { data, isLoading, isFetching, refetch } = useBanners({ page, keyword, type, sortBy, descSortBy, position });
    const { isLoading: isDeletedLoading, mutate } = useDeleteBanner();

    const statusIndex = ref(undefined);

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      position.value = event[0];
      type.value = event[1];
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const deleteBanner = ({ bannerId }: BannerIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        'Are you sure you want to delete?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ bannerId }, {
            onSuccess() {
              refetch.value();

              ElMessage({
                type: 'success',
                message: 'Delete successfully'
              });
            },
            onError(error: any) {
              ElMessage({
                message: error.response?.data.message,
                type: 'error'
              });
            }
          });
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeletedLoading,
      deleteBanner,
      statusIndex,
      searchKeyword,
      FILTER_OPTIONS,
      handleFilterChange,
      t,
      sortChange
    };
  }
});
